import React, { useState, useEffect } from 'react';
import { PageLayout } from '../Layout';
import { useSelector } from 'react-redux';
import TextField from 'components/FormElements/TextField';
import { useRFM } from '../../hooks/useRFM';
import ButtonFilled from '../../../../components/FormElements/ButtonFilled';
import Modal from '../../../../portals/Modal';
import styles from './rfmSettings.module.scss';
import rImage from '../../../../assets/r.png'
import fImage from '../../../../assets/f.png'
import mImage from '../../../../assets/m.png'
import { useTranslation, Trans } from "react-i18next";

export default function Schedule() {
  const { t } = useTranslation()
  const { formik, pending } = useRFM();
  const { info } = useSelector(s => s.branchReducer);
  const [errorModal, setErrorModal] = useState(false);
  const [RFMStatus, setRFMStatus] = useState(null);

  useEffect(() => {
    if (info) {
      if (!info.configuration.rfm_analysis) {
        setRFMStatus(null);
        return setErrorModal(true)
      } else if (info.configuration.rfm_analysis.recency) {
        setRFMStatus({
          rOne: info.configuration.rfm_analysis.recency.one,
          rTwo: info.configuration.rfm_analysis.recency.two,

          fOne: info.configuration.rfm_analysis.frequency.one,
          fTwo: info.configuration.rfm_analysis.frequency.two,

          mOne: info.configuration.rfm_analysis.monetary.one,
          mTwo: info.configuration.rfm_analysis.monetary.two,
        });
      }
    }
  }, [info])

  return (
    <PageLayout
      title={t('NSSidebar.analysis')}
      text={t('NSBonus.information')}
    >
      <div className={styles.container}>
        <p className={styles.text}>
          {t('rfmSettingsText')}
        </p>

        {RFMStatus && (
          <table className={styles.infoTable}>
            <thead>
            <tr>
              <th>
                <img src={rImage} />
                <b>
                  {t('NSRfm.recency')}
                </b>
              </th>
              <th>
                <img src={fImage} />
                <b>{t('NSRfm.frequency')}</b>
              </th>
              <th>
                <img src={mImage} />
                <b>{t('NSRfm.sum')}</b>
              </th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>{t('NSRfm.recent')}</td>
              <td>{t('NSRfm.often')}</td>
              <td>{t('NSRfm.large')}</td>
            </tr>
            <tr>
              <td>{t('NSRfm.inactive')}</td>
              <td>{t('NSRfm.infrequently')}</td>
              <td>{t('NSRfm.average')}</td>
            </tr>
            <tr>
              <td>{t('NSRfm.longtime')}</td>
              <td>{t('NSRfm.rarely')}</td>
              <td>{t('NSRfm.small')}</td>
            </tr>
            </tbody>
          </table>
        )}

        <p className={styles.text} style={{ marginTop: '40px' }}>
          {t('NSRfm.options')}
        </p>

        <form className={styles.form} onSubmit={formik.handleSubmit}>
          <table className={`${styles.formTable}`}>
            <thead>
              <tr>
                <th>
                  <b>R</b> {t('NSRfm.last2')}
                </th>
                <th><b>F</b> {t('NSRfm.transactions2')}</th>
                <th><b>M</b> {t('NSRfm.amount2')}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <TextField
                    label="1"
                    placeholder="Введите число"

                    value={formik.values.recencyOne}
                    error={formik.touched.recencyOne && formik.errors.recencyOne}
                    onChange={(e) => formik.setFieldValue('recencyOne', e.target.value)}
                  />
                  <span>
                    <Trans i18nKey="NSRfm.day">
                     Например, для кофейни  - <br/> 1 день
                    </Trans>
                  </span>
                </td>
                <td>
                  <TextField
                    label="1"
                    placeholder="Введите число"

                    value={formik.values.frequencyOne}
                    error={formik.touched.frequencyOne && formik.errors.frequencyOne}
                    onChange={(e) => formik.setFieldValue('frequencyOne', e.target.value)}
                  />
                  <span>
                    <Trans i18nKey="NSRfm.times">
                     Например, для кофейни  - <br/> 1 день
                    </Trans>
                  </span>
                </td>
                <td>
                  <TextField
                    label="1"
                    placeholder="Введите число"

                    value={formik.values.monetaryOne}
                    error={formik.touched.monetaryOne && formik.errors.monetaryOne}
                    onChange={(e) => formik.setFieldValue('monetaryOne', e.target.value)}
                  />
                  <span>
                    <Trans i18nKey="NSRfm.money">
                     Например, для кофейни  - <br/> 1 день
                    </Trans>
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  <TextField
                    label="3"
                    placeholder="Введите число"

                    value={formik.values.recencyTwo}
                    error={formik.touched.recencyTwo && formik.errors.recencyTwo}
                    onChange={(e) => formik.setFieldValue('recencyTwo', e.target.value)}
                  />
                  <span>
                    <Trans i18nKey="NSRfm.days30">
                      Например, для кофейни  - <br/> 30 дней
                    </Trans>
                  </span>
                </td>
                <td>
                  <TextField
                    label="3"
                    placeholder="Введите число"

                    value={formik.values.frequencyTwo}
                    error={formik.touched.frequencyTwo && formik.errors.frequencyTwo}
                    onChange={(e) => formik.setFieldValue('frequencyTwo', e.target.value)}
                  />
                  <span>
                    <Trans i18nKey="NSRfm.time">
                      Например, для кофейни  - <br/> 1 раз
                    </Trans>
                  </span>
                </td>
                <td>
                  <TextField
                    label="3"
                    placeholder="Введите число"

                    value={formik.values.monetaryTwo}
                    error={formik.touched.monetaryTwo && formik.errors.monetaryTwo}
                    onChange={(e) => formik.setFieldValue('monetaryTwo', e.target.value)}
                  />
                  <span>
                    <Trans i18nKey="NSRfm.money1000">
                      Например, для кофейни  - <br/> 1000 тг
                    </Trans>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
          <ButtonFilled
            onClick={formik.handleSubmit}
            loading={pending}
            disabled={!info || !info.configuration.rfm_analysis}
          >
            {t("save")}
          </ButtonFilled>
        </form>
      </div>
      <Modal
        open={errorModal} size="small"
        onClickClose={() => setErrorModal(false)}>
        <div className={styles.modal}>
          <p>
            {t("optionForbidden")} <br/>
            {t("touchModerator")}
          </p>
          <div>
            <ButtonFilled
              onClick={() => setErrorModal(false)}
            >{t("close")}</ButtonFilled>
          </div>
        </div>
      </Modal>
    </PageLayout>
  )
}
