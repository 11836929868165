import React from 'react';
import { Route, Switch } from 'react-router-dom';

import CommonInfo from '../ComonInfo';
import AccessRights from '../AccessRights';
import ChangePassword from '../ChangePassword';
import NotificationWhatsapp from '../NotificationWhatsapp';
import WhatsAppCode from '../WhatsAppCode';
import HeaderWhatsapp from '../HeaderWhatsapp';
import Photos from '../Photos';
import Qr from '../Qr';
import Schedule from '../Schedule';
import Socials from '../Socials';
import Prizes from '../Prizes';
import RFM from '../RFM';
import ProfileBonus from "../Bonus";
import Surveys from "../Surveys";
import Survey from "../Survey";
import SurveyCreate from "../SurveyCreate";

import styles from './layout.module.scss';

export default function Layout() {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Switch>
          <Route exact path={'/profile'} render={() => <CommonInfo/>}/>
          <Route exact path={'/profile/access-rights'} render={() => <AccessRights/>}/>
          <Route exact path={'/profile/change-password'} render={() => <ChangePassword/>}/>
          <Route exact path={'/profile/notification-whatsapp'} render={() => <NotificationWhatsapp/>}/>
          <Route exact path={'/profile/whatsapp/code'} render={() => <WhatsAppCode/>}/>
          <Route exact path={'/profile/whatsapp-header'} render={() => <HeaderWhatsapp/>}/>
          <Route exact path={'/profile/schedule'} render={() => <Schedule/>}/>
          <Route exact path={'/profile/photos'} render={() => <Photos/>}/>
          <Route exact path={'/profile/socials'} render={() => <Socials/>}/>
          <Route exact path={'/profile/qr'} render={() => <Qr/>}/>
          <Route path={'/profile/bonus'} render={() => <ProfileBonus/>}/>
          <Route exact path={'/profile/prizes'} render={() => <Prizes/>}/>
          <Route exact path={'/profile/rfm'} render={() => <RFM/>}/>
          <Route exact path={'/profile/surveys'} render={() => <Surveys />}/>
          <Route path={'/profile/surveys/:id/:index'} render={() => <Survey />}/>
          <Route path={'/profile/surveys/create'} render={() => <SurveyCreate />}/>
        </Switch>
      </div>
    </div>
  )
}

export const PageLayout = ({ children, navLinksContainer = null }) => {
    if (navLinksContainer) {
        return (
            <div className={styles.layoutContainer}>
                {navLinksContainer()}
                <div>
                    {children}
                </div>
            </div>
        )
    }

    return (
    <div>
      {children}
    </div>
    )
}
