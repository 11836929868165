import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from "react-router-dom";
import transactions from '../../assets/transactions.svg';
import whatsapp from '../../assets/whatsapp.svg';
import analytics from '../../assets/analytics.svg';
import clients from '../../assets/clients.svg';
import profile from '../../assets/profile.svg';
import reviews from '../../assets/reviews.svg';
import security from '../../assets/security.svg';
import ads from '../../assets/ads.svg';
import cross from '../../assets/cross.svg';
import { Roles } from "../../../../config";
import styles from './Navigation.module.css';
import { getAllowedRoutes } from "../../../../tools/utils";
import PrivateRoutesConfig from "../../../../config/PrivateRoutesConfig";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const arrowSvg = (
  <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11 1L6 5L1 1" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);


export default function Navigation() {
    const [collapsed, setCollapsed] = useState(1);
    const [activeIndexFound, setActiveIndexFound] = useState(false);
    const { profileReducer, branchReducer } = useSelector(s => s);
    const { t } = useTranslation();
    const location = useLocation();

    const routes = [
        {
            img: transactions,
            title: t('NSSidebar.transactions'),
            route: '/',
            children: [],
            permission: [
                Roles.CASHIER,
                Roles.ADMINISTRATOR,
                Roles.EMPLOYER,
                Roles.ANALYTIC,
            ],
        },
/*        {
            img: transactions,
            title: t('NSSidebar.transactions_bonus'),
            route: '/bonus-transactions',
            children: [],
            permission: [
                Roles.CASHIER,
                Roles.ADMINISTRATOR,
                Roles.EMPLOYER,
            ],
        },*/
        {
            img: whatsapp,
            title: 'WhatsApp',
            route: '/whatsapp',
            children: [],
            permission: [
                Roles.CASHIER,
                Roles.ADMINISTRATOR,
                Roles.EMPLOYER,
                Roles.ANALYTIC,
            ],
        },
        {
            img: analytics,
            title: t('NSSidebar.analytics'),
            route: '/analytics',
            children: [
                {
                    img: analytics,
                    title: t('NSSidebar.analysis'),
                    route: '/analytics/rfm',
                },
                {
                    img: analytics,
                    title: t('NSSidebar.sales'),
                    route: '/analytics',
                },
                {
                    img: analytics,
                    title: t('NSSidebar.businessReport'),
                    route: '/analytics/business-report',
                },
                {
                    img: analytics,
                    title: t('NSSidebar.new'),
                    route: '/analytics/loyal',
                },
                {
                    img: analytics,
                    title: t('NSSidebar.audience'),
                    route: '/analytics/audit',
                },
                {
                    img: analytics,
                    title: t('bonuses'),
                    route: '/analytics/bonuses',
                }
            ],
            permission: [
                Roles.ADMINISTRATOR,
                Roles.EMPLOYER,
                Roles.ANALYTIC,
            ],
        },
        {
            img: clients,
            title: t('NSSidebar.clients'),
            route: '/clients',
            children: [],
            permission: [
                Roles.ADMINISTRATOR,
                Roles.EMPLOYER,
                Roles.CASHIER,
                Roles.ANALYTIC,
            ],
        },
        {
            img: clients,
            title: t('dataOfClients'),
            route: '/clients-database',
            children: [],
            permission: [
                Roles.ADMINISTRATOR,
                Roles.EMPLOYER,
                Roles.CASHIER,
                Roles.ANALYTIC,
            ],
        },
        {
            img: profile,
            title: t('NSSidebar.profile'),
            route: '/profile',
            children: [
                {
                    img: profile,
                    title: t('NSSidebar.info'),
                    route: '/profile',
                },
                {
                    img: profile,
                    title: t('accessRights'),
                    route: '/profile/access-rights',
                    permission: [
                        Roles.ANALYTIC,
                    ],
                },
                {
                    img: profile,
                    title: t('NSSidebar.changePassword'),
                    route: '/profile/change-password',
                },
                {
                    img: whatsapp,
                    title: t('notificationLang'),
                    route: '/profile/notification-whatsapp',
                },
                {
                    img: whatsapp,
                    title: t('conf_whatsapp_code'),
                    route: '/profile/whatsapp/code',
                },
                {
                    img: whatsapp,
                    title: t('whatsappHeader'),
                    route: '/profile/whatsapp-header',
                },
                {
                    img: profile,
                    title: t('NSSidebar.work'),
                    route: '/profile/schedule',
                },
                {
                    img: profile,
                    title: t('NSSidebar.photo'),
                    route: '/profile/photos',
                },
                {
                    img: profile,
                    title: t('NSSidebar.networks'),
                    route: '/profile/socials',
                },
                // {
                //     img: profile,
                //     title: t('NSSidebar.code'),
                //     route: '/profile/qr',
                // },
                {
                    img: profile,
                    title: t('NSSidebar.bonus'),
                    route: '/profile/bonus/term',
                },
                {
                    img: profile,
                    title: t('NSSidebar.awards'),
                    route: '/profile/prizes',
                },
                {
                    img: profile,
                    title: t('NSSidebar.analysis'),
                    route: '/profile/rfm',
                },
                {
                    img: profile,
                    title: t('NSSidebar.poll'),
                    route: '/profile/surveys',
                },
            ],
            permission: [
                Roles.ADMINISTRATOR,
                Roles.EMPLOYER,
                Roles.ANALYTIC,
            ],
        },
        {
            img: reviews,
            title: t('NSSidebar.reviews'),
            route: '/reviews',
            children: [],
            permission: [
                Roles.CASHIER,
                Roles.ADMINISTRATOR,
                Roles.EMPLOYER,
                Roles.ANALYTIC,
            ],
        },
        {
            img: ads,
            title: t('NSSidebar.ads'),
            route: '/ads',
            children: [
                {
                    img: null,
                    title: t('NSSidebar.info'),
                    route: '/ads',
                },
                {
                    img: null,
                    title: t('NSSidebar.comparison'),
                    route: '/ads/comparison',
                },
            ],
            permission: [
                // Roles.CASHIER,
                Roles.ADMINISTRATOR,
                Roles.EMPLOYER,
                Roles.ANALYTIC,
            ],
        },
        {
            img: cross,
            title: t('NSSidebar.cross'),
            route: '/cross-marketing',
            children: [
                {
                    img: null,
                    title: t('NSSidebar.administration'),
                    route: '/cross-marketing',
                },
                {
                    img: null,
                    title: t('NSSidebar.analytics1'),
                    route: '/cross-marketing/analytics',
                },
            ],
            permission: [
                // Roles.CASHIER,
                Roles.ADMINISTRATOR,
                Roles.EMPLOYER,
                Roles.ANALYTIC,
            ],
        },
        {
            img: security,
            title: t('NSSidebar.security'),
            route: '/security',
            children: [],
            permission: [
                // Roles.CASHIER,
                Roles.ADMINISTRATOR,
                Roles.ANALYTIC,
                // Roles.EMPLOYER,
            ],
        },
    ]

    let currentBranch = { role: Roles.ADMINISTRATOR };

    if (profileReducer.info && profileReducer.info.branches.length && branchReducer.info && branchReducer.info.id) {
        currentBranch = profileReducer.info.branches.find(b => b.id === branchReducer.info.id)
    }

    let allowedRoutes = [];
    if (currentBranch) {
        allowedRoutes = getAllowedRoutes(routes, [currentBranch.role]);
    }

    useEffect(() => {
        setActiveIndexFound(false);
    }, [location.pathname]);

    const changeIndex = (indx) => {
        if (collapsed === indx) return setCollapsed(0);
        setCollapsed(indx);
    }

    const renderBtn = (item, index) => {
        if (location.pathname.split('NSSidebar./')[1] === item.route.slice(1) && !activeIndexFound) {
            if (collapsed !== index) {
                setCollapsed(index);
                setActiveIndexFound(true);
            }
        }

        if (item.route === '/') {
            return (
              <NavLink
                to={item.route}
                exact
                activeClassName={index === collapsed ? styles.activeTab : ''}
                className={styles.btn} key={index}
                onClick={() => changeIndex(index)}>
                  <img src={item.img}/>
                  <span>{item.title}</span>
              </NavLink>
            )
        }
        if (item.children.length) {
            return (
              <React.Fragment key={index}>
                  <button
                    to={item.route}
                    className={`${styles.btn} ${index === collapsed ? styles.activeTab : ''}`}
                    onClick={() => changeIndex(index)}
                  >
                      <img src={item.img}/>
                      <span>{item.title}</span>
                      <i>{arrowSvg}</i>
                  </button>
                  <div className={`${styles.wrapper} ${index === collapsed ? styles.collapsed : ''}`}>
                      {
                          item.children.map((child) => (
                            <NavLink to={child.route} exact activeClassName={styles.linkActive} className={styles.link} key={child.title}>
                                <span>{child.title}</span>
                            </NavLink>
                          ))
                      }
                  </div>
              </React.Fragment>
            )
        }

        return (
          <NavLink to={item.route} activeClassName={index === collapsed ? styles.activeTab : ''} className={styles.btn} key={index}
                   onClick={() => changeIndex(index)}>
              <img src={item.img}/>
              <span>{item.title}</span>
          </NavLink>
        )
    }

    return (
        <div className={styles.container}>
            {allowedRoutes.map((item, index) => renderBtn(item, index + 1))}
        </div>
    )
}
