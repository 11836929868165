const Roles = {
  ADMINISTRATOR: 'administrator',
  CASHIER: 'cashier',
  EMPLOYER: 'employer',
  ANALYTIC: 'analytic',
  UNKNOWN: 'unknown',
};

export const roleOptions = [
  { label: Roles.ADMINISTRATOR, value: Roles.ADMINISTRATOR },
  { label: Roles.CASHIER, value: Roles.CASHIER },
  { label: Roles.EMPLOYER, value: Roles.EMPLOYER },
  { label: Roles.ANALYTIC, value: Roles.ANALYTIC },
]

export const roleOptionStrings = [
  Roles.ADMINISTRATOR,
  Roles.CASHIER,
  Roles.EMPLOYER,
  Roles.ANALYTIC,
]

 export default Roles