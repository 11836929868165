import instance from "../../api";
import queryString from "querystring";

const profileApi = {
    getProfile: () => {
        return instance().get(`/business-users/@me`).then(res => res.data)
    },
    changePassword: (body) => {
        return instance().post(`/user/@me/reset`, body).then(res => res.data)
    },

    getProfileBusinessUsersGroupsList: (groupId, params) => {
        const prms = queryString.stringify(params, '&', '',{
            skipNull: true,
            skipEmptyString: true
        })
        return instance().get(`/business-users/groups/${groupId}/list?${prms}`).then(res => res.data)
    },
    putBusinessUsers: (groupId, body) => {
        // {
        //     "branches": [
        //     {
        //         "id": 1833841116177240064,
        //         "role": "analytic"
        //     },
        //     {
        //         "id": "1835322529523175424",
        //         "role": "analytic"
        //     }
        // ],
        //   "name": "",
        //   "email": "testiii@gmail.com"
        // }
        return instance().put(`/business-users/${groupId}`, body).then(res => res.data)
    },
}

export default profileApi;