import React, {useState, useEffect} from 'react';
import { useTranslation } from "react-i18next";
import { PageLayout } from '../Layout';
import profileApi from "../../../../store/profileStore/profileApi";
import {useDispatch, useSelector} from "react-redux";
import { store } from 'react-notifications-component';
import styles from './accessRights.module.scss'
import {roleOptions} from "../../../../config/Roles";
import CustomSelect from "../../../../components/FormElements/Select";
import _ from "lodash";
import ButtonFilled from "../../../../components/FormElements/ButtonFilled";
import profileActions from "../../../../store/profileStore/profileActions";

const locationSvg = (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M5.27243 10.3599C5.366 6.42516 8.36118 3.25883 12.0832 3.15991C15.8053 3.25883 18.8005 6.42516 18.8941 10.3599C18.8262 11.5245 18.5175 12.6592 17.9897 13.6839L17.9368 13.7799C17.8989 13.8519 16.9605 15.4519 15.867 17.0519C13.7822 20.1519 12.9005 20.7599 12.0832 20.7599C11.5611 20.7599 10.3313 20.7599 6.21459 13.7839L6.16162 13.6839C5.63909 12.6579 5.33558 11.5233 5.27243 10.3599ZM12.0832 19.5599C13.2184 19.5599 17.0022 13.1599 17.0022 13.1599H16.9908C17.4347 12.296 17.6967 11.3408 17.7589 10.3599C17.7589 7.0462 15.2178 4.35991 12.0832 4.35991C8.94865 4.35991 6.40757 7.0462 6.40757 10.3599C6.46631 11.3399 6.72444 12.295 7.16432 13.1599C7.16432 13.1599 10.9481 19.5599 12.0832 19.5599ZM9.05621 9.95991C9.05621 8.1926 10.4115 6.75991 12.0832 6.75991C13.755 6.75991 15.1103 8.1926 15.1103 9.95991C15.1103 11.7272 13.755 13.1599 12.0832 13.1599C10.4115 13.1599 9.05621 11.7272 9.05621 9.95991ZM10.1913 9.95991C10.1913 11.0645 11.0384 11.9599 12.0832 11.9599C13.1281 11.9599 13.9751 11.0645 13.9751 9.95991C13.9751 8.85534 13.1281 7.95991 12.0832 7.95991C11.0384 7.95991 10.1913 8.85534 10.1913 9.95991Z" fill="url(#paint0_linear)"/>
    <defs>
      <linearGradient id="paint0_linear" x1="12.0832" y1="3.15991" x2="12.0832" y2="20.7599" gradientUnits="userSpaceOnUse">
        <stop stopColor="#73D389"/>
        <stop offset="1" stopColor="#4EB78B"/>
      </linearGradient>
    </defs>
  </svg>
);

export default function AccessRights() {
  const dispatch = useDispatch()
  const { t } = useTranslation();
  const branch = useSelector((state) => state.branchReducer)
  const profile = useSelector((state) => state.profileReducer)
  const [businessUsers, setBusinessUsers] = useState([])
  const [saving, setSaving] = useState(false)
  const [loading, setLoading] = useState(false)

  const getProfileBusinessUsers = async () => {
    setLoading(true)
    try {
      const response = await profileApi.getProfileBusinessUsersGroupsList(branch.info.group_id)
      setBusinessUsers(response?.data || [])
    } catch (e) {
      store.addNotification({
        title: t('error'),
        message: `${e?.response?.data?.message ? e.response.data.message : t('Notify.error')}`,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      });
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (branch.info) {
      getProfileBusinessUsers()
    }
  }, [branch.info])

  const saveBusinessUsers = async (businessUserId, businessUser) => {
    setSaving(true)
    try {
      await profileApi.putBusinessUsers(businessUserId, businessUser)
      dispatch(profileActions.getProfile());

      store.addNotification({
        title: t('success'),
        message: t('profileDataSuccessfullyUpdated'),
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      });
    } catch (e) {
      store.addNotification({
        title: t('error'),
        message: `${e?.response?.data?.message ? e.response.data.message : t('Notify.error')}`,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      });
    } finally {
      setSaving(false)
    }
  }

  return (
    <PageLayout
      title={t('accessRights')}
      text={t('accessRights')}
    >
      <ul className={styles.list}>
        {businessUsers?.map((businessUser, businessUserIndex) => (
          <li key={businessUser.id}>
            <ul className={styles.list}>
              {businessUser.branches?.map((branch, branchIndex) => (
                <li style={{padding: '8px 0'}}>
                  <p>{branch.name}</p>
                  <CustomSelect
                    options={roleOptions}
                    value={{label: branch.role, value: branch.role}}
                    onSelect={(o) => {
                      if (loading || saving) return
                      const copiedBusinessUsers = _.cloneDeep(businessUsers)
                      copiedBusinessUsers[businessUserIndex].branches[branchIndex].role = o.value
                      setBusinessUsers(copiedBusinessUsers)
                    }}
                  />
                </li>
              ))}
            </ul>
            <div style={{height: 16}}/>
            <ButtonFilled
              disabled={!businessUsers?.length || !businessUser?.id}
              loading={saving || loading}
              onClick={() => saveBusinessUsers(businessUser.id, businessUser)}
            >
              Сохранить
            </ButtonFilled>
          </li>
        ))}
      </ul>
    </PageLayout>
  )
}